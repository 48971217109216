﻿import { html, css, LitElement } from 'lit';
import router from '../../router';
import { customElement } from 'lit/decorators.js';

@customElement('weekly-link')
export default class WeeklyLink extends LitElement {
  static get styles() {
    return css`
      a {
        color: inherit;
        text-decoration: inherit;
      }
    `;
  }

  static get properties() {
    return {
      href: { type: String }
    };
  }

  static router = undefined;

  constructor() {
    super();

    this.addEventListener('click', this.handleClick);
  }

  render() {
    const { href, handleClick } = this;

    return html`
      <a href=${href} @click=${handleClick}>
        <slot></slot>
      </a>
    `;
  }

  handleClick = event => {
    const target = event.currentTarget.shadowRoot?.querySelector('a') || event.currentTarget;

    event.stopPropagation();
    event.preventDefault();

    router.navigate(target.href.replace(document.baseURI, ''));
  };
}