// Prepend all the strings with dev- to avoid collision with the real routes.
import { html } from 'lit';

export const DevRoutes = {
  Home: 'dev-home',
  FancyTableDemo: 'fancy-table-demo',
  SkiaSpeedTest: 'skia-speed-test'
};

export const devRoutes = {
  [DevRoutes.Home]: {
    route: '/dev',
    view: () => import('./components/views/dev-home.js'),
    title: 'Dev Home',
    render: () => html`<dev-home></dev-home>`
  },
  [DevRoutes.SkiaSpeedTest]: {
    route: '/dev/skia-speed-test',
    view: () => import('./components/views/skia-speed-test.js'),
    title: 'Skia Speed Test',
    render: () => html`<skia-speed-test></skia-speed-test>`
  },
  [DevRoutes.FancyTableDemo]: {
    route: '/dev/fancy-table',
    view: () => import('./components/views/fancy-table-demo.js'),
    title: 'Fancy Table Demo',
    render: () => html`<fancy-table-demo></fancy-table-demo>`
  }
};